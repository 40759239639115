import React from "react"
import Container from "../components/structural/Container"
import SubLayout from "../components/Sublayout"

import styled from "styled-components"
import Button from "../components/Button"
import { FormattedMessage } from "react-intl"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 32px;
  align-items: center;
  padding: 32px;
  text-align: center;
`

export default function Sponsorship() {
  return (
    <SubLayout>
      <Container heading="sponsorship.heading">
        <Card>
          <h1>
            <FormattedMessage id="sponsorship.h1" />
          </h1>
          <p>
            <FormattedMessage id="sponsorship.p1" />
          </p>
          <a href="/projects">
          <Button>
            <FormattedMessage id="sponsorship.btn" />
          </Button>
          </a>
          <p>
            <FormattedMessage id="sponsorship.p2" />
          </p>
        </Card>
      </Container>
    </SubLayout>
  )
}
